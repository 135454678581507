
import moment from 'moment';
import { Vue, Component, Watch } from 'vue-property-decorator';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import MultipleSelect from '@/components/global/MultipleSelect.vue';
// import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import CalendarTopBar from '@/components/views/Calendar/CalendarTopBar.vue';
import ReminderOption from '@/models/ReminderOption';
import NextStepType from '@/models/NextStepType';
import NextStepRepository from '@/repositories/NextStepRepository';
import NextStep from '@/models/NextStep';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import CMSUser from '@/models/CMSUser';
import User from '@/models/User';
import { UserRepository } from '@/repositories/UserRepository';
import Schedule from '@/models/Schedule';
import Resource from '@/models/Resource';
import ScheduleRepository from '@/repositories/ScheduleRepository';
import ResourceRepository from '@/repositories/ResourceRepository';
import slLocale from '@fullcalendar/core/locales/sl';
import enLocale from '@fullcalendar/core/locales/en-gb';
import hrLocale from '@fullcalendar/core/locales/hr';
import deLocale from '@fullcalendar/core/locales/de-at';
import huLocale from '@fullcalendar/core/locales/hu';
import itLocale from '@fullcalendar/core/locales/it';
import LocaleRepository from '@/repositories/LocaleRepository';
import Locale from '@/models/Locale';
import Pdf from '@/models/Pdf';
import { CalendarEvent } from '@/interfaces/components/calendar/CalendarEvent';
import { CalendarResource } from '@/interfaces/components/calendar/CalendarResource';
import { CalendarPDF } from '@/interfaces/components/calendar/CalendarPDF';
// @ts-ignore
import downloadFile from 'downloadjs';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { SessionStorageService as ss } from '@/services/SessionStorageService';
import store from '@/store';
import { UserRightsEnum } from '@/enums/global/UserRights';

@Component({
    name: 'Calendar',
    components: {
        CalendarTopBar,
        SettingsTopBar,
        FullCalendar,
        MultipleSelect,
    },
})
export default class Calendar extends Vue {
    private isCalendarLoading = true;
    private calendarOptions = {
        // timeZone: 'CET',
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin],
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        initialView: 'dayGridMonth',
        locale: slLocale,
        editable: true,
        eventStartEditable: true,
        eventDurationEditable: true,
        eventOverlap: true,
        eventBackgroundColor: 'lime',
        events: [] as any[],
        themeSystem: 'bootstrap5',
        navLinks: true,
        eventDrop: this.handleEventDrop,
        dateClick: this.handleDateClick,
        eventClick: this.handleEventChange,
        eventResize: this.handleResizeEvent,
        datesSet: (info: any) => {
            this.debounce(300, info);
        },
        resources: [] as any,
        allDaySlot: false,
        eventDisplay: 'auto',
        headerToolbar: {
            start: 'prev,next,today',
            center: 'title',
            // tslint:disable-next-line:max-line-length
            end: 'dayGridMonth,timeGridWeek,resourceTimeGridDay',
        },
        views: {
            day: {
                // name of view
                titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'short' },
                // other view-specific options here
            },
        },
        // views: {
        //     resourceTimeGridDay: {
        //         // or whatever the daily view is named in your configuration
        //         type: 'timeGrid',
        //         duration: { days: 1 }, // Make sure the duration is set to one day
        //     },
        // },
    };
    private selectedProject = null;
    private selectedResources: CalendarResource[] = [];
    private selectedUsers: CMSUser[] = [];
    private currentUser: User | null = null;
    private scheduleEvents: CalendarEvent[] = [];
    private projectsWithActivities: any = [];
    private currentView = 'dayGridMonth';
    private originalEvents: CalendarEvent[] = [];
    private isPrinting = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private isUpdatingCalendarPage = false;
    private isUserStateUpdating = false;
    private lastTimeStamp = 0;
    private saveActivityHandler: ((args: any) => Promise<void>) | null = null;
    private updateActivityHandler: ((args: any) => Promise<void>) | null = null;
    private deleteActivityHandler: ((args: any) => Promise<void>) | null = null;

    private get resources() {
        return ResourceRepository.getAll();
    }

    private get schedules() {
        return ScheduleRepository.getAll();
    }

    private get shouldShowPrintButton() {
        return this.currentView === 'resourceTimeGridDay';
    }

    private get activeUsers() {
        return CMSUserRepository.getAllExceptArchivedUsers();
    }

    private get currentLocale() {
        return LocaleRepository.getLocaleByCode(this.$i18n.locale);
    }

    private get canEditActivity() {
        const userRights = store.getters['jwtData/userRights'];
        const canSeeEditNextSteps = userRights.includes(UserRightsEnum.EDIT_NEXT_STEPS);
        return canSeeEditNextSteps;
    }

    private get canDeleteActivity() {
        const userRights = store.getters['jwtData/userRights'];
        const canSeeDeleteNextSteps = userRights.includes(UserRightsEnum.DELETE_NEXT_STEPS);
        return canSeeDeleteNextSteps;
    }

    private openActivitiesProjectsModal() {
        EventBus.$emit(EventBusEvents.openActivitiesProjectsModal, {
            popupEvent: EventBusEvents.openActivitiesProjectsModal,
            data: null,
        });
    }

    private async onMadeByUserSelect(e: any) {
        this.isUserStateUpdating = true;
        this.selectedUsers = [];
        const userIds: string[] = [];

        e.forEach((id: any) => {
            const user = this.activeUsers.find((i) => i.id === id);

            if (user) {
                this.selectedUsers.push(user);
                userIds.push(user.id);
            }
        });

        ss.stringifyAndSave('selectedCalendarUsers', userIds);
        await this.updateActivitiesAndResetCalendar();

        this.updateSchedules(this.selectedUsers);

        this.isUserStateUpdating = false;
    }

    private updateSchedules(e: any) {
        // @ts-ignore
        this.calendarOptions.resources = [...this.selectedResources];

        e.forEach((item: any) => {
            const user = this.activeUsers.find((sub: any) => sub.id === item.id);

            this.calendarOptions.resources.push({
                id: 'u-' + user?.id,
                title: user?.name,
            });

            // @ts-ignore
            const schedules = this.schedules.filter((i: any) => String(i.userId) === String(user?.id));

            schedules.forEach((schedule: any) => {
                const { formattedDateFrom, formattedDateTo } = this.formatDatesForCalendar(
                    Number(schedule.dateTimeFrom),
                    Number(schedule.dateTimeTo)
                );
                const { formattedDateFrom: activityDateFrom, formattedDateTo: activityDateTo } =
                    this.formatDatesForCalendar(Number(schedule.dateTimeFrom), Number(schedule.dateTimeTo), 'activity');

                const obj = {
                    id: schedule.id,
                    eventId: schedule.id ?? '',
                    title: schedule.title ?? '',
                    start: activityDateFrom,
                    end: activityDateTo,
                    resourceId: 'u-' + user?.id,
                    display: 'background',
                    formattedDateFrom,
                    formattedDateTo,
                };

                this.calendarOptions.events.push(obj);
                this.originalEvents.push(obj);
            });
        });

        this.filterEvents();
    }

    private getLocaleForCalendar() {
        if (this.currentLocale) {
            switch (this.currentLocale?.code) {
                case 'sl':
                    return slLocale;
                case 'hr':
                    return hrLocale;
                case 'en':
                    return enLocale;
                case 'de':
                    return deLocale;
                case 'it':
                    return itLocale;
                case 'hu':
                    return huLocale;
            }
        }
        return slLocale;
    }

    private setSelectedUsers() {
        if (ss.has('selectedCalendarUsers')) {
            this.selectedUsers = [];
            const selectedUsers = ss.getAndParse('selectedCalendarUsers');

            selectedUsers.forEach((id: string) => {
                const user = this.activeUsers.find((i) => i.id === id);

                if (user) {
                    this.selectedUsers.push(user);
                }
            });
        } else {
            // @ts-ignore
            this.selectedUsers = [this.currentUser];
        }
    }

    private async created() {
        this.isCalendarLoading = true;
        this.isUpdatingCalendarPage = true;

        await this.loadCalendarData();
        await this.$nextTick();

        this.isCalendarLoading = false;
        this.isUpdatingCalendarPage = false;
    }

    private async loadCalendarData() {
        try {
            await Promise.all([
                Locale.getAll(),
                CMSUser.getAllOnlyUsers(),
                NextStepType.getAll(),
                ReminderOption.getAll(),
                Schedule.getAll(),
                Resource.getAll(),
            ]);

            this.calendarOptions.locale = this.getLocaleForCalendar();
            this.currentUser = UserRepository.getCurrentUser();
            this.setSelectedUsers();

            await this.updateActivities();
            this.updateSchedules(this.selectedUsers);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private async handleEventDrop($event: any) {
        if (this.canEditActivity) {
            await this.updateDraggedEvent($event);
        } else {
            this.$notification.error({
                message: this.$t("You don't have user right to edit activities") as string,
                description: '',
            });
        }
    }

    private async handleResizeEvent($event: any) {
        if (this.canEditActivity) {
            await this.updateDraggedEvent($event);
        } else {
            this.$notification.error({
                message: this.$t("You don't have user right to edit activities") as string,
                description: '',
            });
        }
    }

    private async onDelete(e: any) {
        if (this.canDeleteActivity) {
            try {
                if (!e) {
                    throw new Error('Activity not set');
                }

                await NextStep.deleteExisting(e.id);
                const index = this.calendarOptions.events.findIndex((item: any) => item.eventId === e.id);

                if (index !== -1) {
                    this.calendarOptions.events.splice(index, 1);
                    this.originalEvents.splice(index, 1);
                }
            } catch (error) {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: '',
                });
                return;
            }

            this.$notification.success({
                message: this.$t('Activity deleted successfully'),
                description: ``,
            });
        } else {
            this.$notification.error({
                message: this.$t("You don't have user right to delete activities") as string,
                description: '',
            });
        }
    }

    private clearProjects() {
        this.selectedProject = null;
        this.projectsWithActivities = [];
        this.calendarOptions.events = [];
        this.originalEvents = [];
    }

    private clearUsers() {
        this.selectedUsers = [];
        ss.remove('selectedCalendarUsers');

        this.updateActivitiesAndResetCalendar();
    }

    private handleAddNewEvent() {
        EventBus.$emit(EventBusEvents.openCreateNewEventPopup, {
            popupEvent: EventBusEvents.openCreateNewEventPopup,
            data: { eventData: { canEditActivity: this.canEditActivity, canDeleteActivity: this.canDeleteActivity } },
        });
    }

    private openProjectsModal() {
        EventBus.$emit(EventBusEvents.closePopup);
        EventBus.$emit(EventBusEvents.openProjectsModal, {
            popupEvent: EventBusEvents.openProjectsModal,
            data: {
                origin: 'rootCalendar',
                originData: null,
                selectedUserId: this.currentUser?.id,
            },
        });
    }

    @Watch('currentView', {
        immediate: true,
    })
    private async handleViewChange() {
        this.filterEvents();
    }

    private filterEvents() {
        if (this.currentView === 'dayGridMonth') {
            this.calendarOptions.events = this.originalEvents.filter((event) => {
                if (event.resourceId && event.resourceId.substring(0, 2) === 'r-') {
                    return false;
                }

                return true;
            });
        } else {
            this.calendarOptions.events = this.originalEvents.slice();
        }
    }

    private async updateActivities() {
        // @ts-ignore
        this.calendarOptions.events = [...this.scheduleEvents];
        this.originalEvents = [...this.scheduleEvents];
        let query = '';
        // @ts-ignore
        if (this.selectedProject?.id) {
            // @ts-ignore
            query = '&filter[project.id]=' + this.selectedProject?.id;
        }
        const currentStart = moment(
            // @ts-ignore
            this.$refs.calendar.$options.parent.$refs.calendar.calendar.view.currentStart
        ).format('YYYY-MM-DD');
        // @ts-ignore
        const currentEnd = moment(this.$refs.calendar.$options.parent.$refs.calendar.calendar.view.currentEnd).format(
            'YYYY-MM-DD'
        );
        await NextStep.deleteAll();
        if (this.selectedUsers.length) {
            query += `&q=((${this.selectedUsers
                .map((item: any) => 'assignedUser.id:' + item.id)
                .join(
                    '|'
                )})^((dateFrom>${currentStart}^dateFrom<${currentEnd})|(dateTo>${currentStart}^dateTo<${currentEnd})))`;
            try {
                await NextStep.getFilteredProjects(query);
            } catch {
                this.$notification.error({
                    message: this.$t('Error'),
                    description: this.$t('Error fetching activities'),
                });
                this.isCalendarLoading = false;
                this.isUpdatingCalendarPage = false;
            }
        }

        this.updateCalendarEvents();

        return Promise.resolve();
    }

    private async updateCalendarEvents() {
        this.projectsWithActivities = NextStepRepository.getNextStepsWithProjects();

        if (!this.projectsWithActivities.length) {
            this.isCalendarLoading = false;
        } else {
            this.projectsWithActivities.forEach((item: NextStep) => {
                const obj = this.formatEventObject(item);
                this.calendarOptions.events.push(obj);
                this.originalEvents.push(obj);
            });

            this.filterEvents();
        }
    }

    private formatEventObject(item: NextStep) {
        const { formattedDateFrom, formattedDateTo } = this.formatDatesForCalendar(item.dateFrom, item.dateTo);
        const { formattedDateFrom: activityDateFrom, formattedDateTo: activityDateTo } = this.formatDatesForCalendar(
            item.dateFrom,
            item.dateTo,
            'activity'
        );

        const eventObject: CalendarEvent = {
            id: item.id,
            eventId: item.id,
            title: item.name,
            start: activityDateFrom,
            end: activityDateTo,
            project: item.project,
            reminderOption: item.reminderOption,
            localDateFrom: activityDateFrom,
            localDateTo: activityDateTo,
            formattedDateFrom,
            formattedDateTo,
            activityType: item.type,
            localReminderOption: item.reminderDate,
            eventDescription: item.note,
            selectedUser: item.assignedUser,
            resourceId: 'u-' + (item.assignedUser?.id ?? ''),
            completed: item.completed,
        };

        return eventObject;
    }

    private handleSaveProject(e: any) {
        if (typeof e === 'object' && e.rootCalendar) {
            this.selectedProject = e.project;
            this.updateActivitiesAndResetCalendar();
        } else {
            this.selectedProject = e;
        }
    }

    private async onSave(e: any) {
        const projectId = e.project.id;

        if (!projectId) {
            return;
        }

        try {
            const nextStepInfo = {
                name: e.eventName,
                dateFrom: e.localDateFrom,
                dateTo: e.localDateTo,
                note: e.eventDescription,
                completed: e.completed,
                projectId: projectId,
                nextStepType: e.activityType?.id,
                reminderOption: e.reminderOption?.id,
                assignedUser: e.selectedUser?.id,
            };

            const nextStep = await NextStep.createNew(nextStepInfo);
            const eventProject = NextStepRepository.getById(nextStep.data.id) as NextStep;

            const obj = this.formatEventObject(eventProject);

            this.calendarOptions.events.push(obj);
            this.originalEvents.push(obj);

            this.$notification.success({
                message: this.$t('Activity je uspješno stvoren') as string,
                description: '',
            });
        } catch (e) {
            this.$notification.error({
                message: this.$t('Došlo je do greške') as string,
                description: '',
            });
        }
    }

    private formatDataForNextStepApiFromEventApi(eventApi: any) {
        const eventObj = eventApi.event._def.extendedProps;

        const nextStepInfo = {
            name: eventApi.event.title,
            dateFrom: eventApi.event.start,
            dateTo: eventApi.event.end,
            note: eventObj.eventDescription,
            completed: eventObj.completed,
            leadId: eventObj.project?.lead?.$id,
            nextStepType: eventObj.activityType?.id,
            reminderOption: eventObj.reminderOption?.id,
            assignedUser: eventObj.selectedUser?.id,
        };

        if (!nextStepInfo.dateTo) {
            nextStepInfo.dateTo = nextStepInfo.dateFrom;
        }

        return nextStepInfo;
    }

    private async updateDraggedEvent(eventApi: any) {
        const nextStepInfo = this.formatDataForNextStepApiFromEventApi(eventApi);
        const eventId = eventApi.event._def.extendedProps.eventId;

        try {
            this.isUpdatingCalendarPage = true;
            await NextStep.updateExisting(eventId, nextStepInfo);

            const eventProject = NextStepRepository.getById(eventId) as NextStep;
            const index = this.calendarOptions.events.findIndex((item: any) => item.eventId === eventProject.id);

            if (index !== -1) {
                const eventObject = this.formatEventObject(eventProject);
                this.$set(this.calendarOptions.events, index, eventObject);
                this.$set(this.originalEvents, index, eventObject);
            }
        } catch (e) {
            this.$notification.error({
                message: this.$t('Došlo je do greške') as string,
                description: '',
            });
        } finally {
            await this.$nextTick();
            this.isUpdatingCalendarPage = false;
        }

        this.$notification.success({
            message: this.$t('Activity is successfully updated') as string,
            description: '',
        });

        return Promise.resolve();
    }

    private async onUpdate(e: any) {
        const projectId = e.project.id;

        if (!projectId) {
            return;
        }

        try {
            const nextStepInfo = {
                name: e.eventName,
                dateFrom: e.localDateFrom,
                dateTo: e.localDateTo,
                note: e.eventDescription,
                completed: e.completed,
                projectId: projectId,
                nextStepType: e.activityType?.id,
                reminderOption: e.reminderOption?.id,
                assignedUser: e.selectedUser?.id,
            };

            await NextStep.updateExisting(e.id, nextStepInfo);
            const eventProject = NextStepRepository.getById(e.id) as NextStep;
            const index = this.calendarOptions.events.findIndex((item: any) => item.eventId === eventProject.id);

            if (index !== -1) {
                const eventObject = this.formatEventObject(eventProject);

                this.$set(this.calendarOptions.events, index, eventObject);
                this.$set(this.originalEvents, index, eventObject);
            }

            this.$notification.success({
                message: this.$t('Activity is successfully updated') as string,
                description: '',
            });
        } catch (e) {
            this.$notification.error({
                message: this.$t('Došlo je do greške') as string,
                description: '',
            });
        }
    }

    private handleDateClick(arg: any) {
        let selectedDate = arg.dateStr;
        if (selectedDate.length === 10) {
            selectedDate += 'T00:00:00+01:00';
        }

        EventBus.$emit(EventBusEvents.openCreateNewEventPopup, {
            popupEvent: EventBusEvents.openCreateNewEventPopup,
            data: {
                eventData: {
                    localDateFrom: selectedDate,
                    localDateTo: selectedDate,
                    selectedUser: this.selectedUsers.length === 1 ? this.selectedUsers[0] : null,
                    canEditActivity: this.canEditActivity,
                    canDeleteActivity: this.canDeleteActivity,
                },
            },
        });
    }

    private async handlePrintCalendar() {
        let scheduleHeights;
        this.isPrinting = true;
        // emit print event to hide sider and topbar and force resize on calendar
        EventBus.$emit(EventBusEvents.print, true);
        const popover = document.querySelector('.ant-popover') as HTMLElement;
        if (popover) {
            popover.style.visibility = 'hidden';
        }

        scheduleHeights = this.getSchedulesHeights();
        this.hideEmptyRows();

        await this.$nextTick();
        window.dispatchEvent(new Event('resize'));
        this.adjustSchedulesHeight(scheduleHeights);
        this.adjustCalendarHeight();
        // We wait for resize to finish and then open print dialog
        // Afterwards we emit print event to show sider and topbar again

        setTimeout(() => {
            window.print();
            this.isPrinting = false;
            EventBus.$emit(EventBusEvents.print, false);
            // @ts-ignore
            popover.style.visibility = 'visible';
            // We wait for print dialog to close and then show empty rows again and reset css
            this.resetCalendarStyles();
            window.dispatchEvent(new Event('resize'));
        }, 300);
    }

    private hideEmptyRows() {
        // @ts-ignore
        const currentDate = new Date(
            // @ts-ignore
            this.$refs.calendar.$options.parent.$refs.calendar.calendar.currentData.currentDate
        ).getTime();

        const filteredEvents = this.calendarOptions.events.filter((event: any) => {
            // filter events that are today only
            const eventStart = new Date(event.start).getTime();
            const eventEnd = new Date(event.end).getTime();
            return true;
            /*return (
                (eventStart >= currentDate && eventStart <= currentDate + 86400000) ||
                (event?.display === 'background' && currentDate <= eventEnd)
            );*/
        });

        // Find hours that have events happening and include hours between start and end date
        const hours = filteredEvents.flatMap((event: any) => {
            const start = new Date(event.start);
            const end = event.end ? new Date(event.end) : new Date(event.start);
            const hoursArr = new Set();

            // add all hours between start and end time
            for (let i = start.getHours() - 1; i <= end.getHours() + 1; i++) {
                hoursArr.add(i);
            }

            return Array.from(hoursArr);
        });

        // Hide rows that don't have events
        const rows = document.querySelector('.fc-timegrid-slots')?.querySelectorAll('tr');
        rows?.forEach((row) => {
            const hour = row.querySelector('.fc-timegrid-slot-label')?.getAttribute('data-time')?.split(':')[0];
            if (hour && !hours.includes(parseInt(hour, 10))) {
                row.style.display = 'none';
            }
        });
    }

    private getSchedulesHeights() {
        const heights: number[] = [];
        const schedules = document.querySelectorAll('.fc-timegrid-bg-harness');

        schedules.forEach((schedule) => {
            heights.push(schedule.clientHeight);
        });

        return heights;
    }

    private adjustSchedulesHeight(heights: number[]) {
        const schedules = document.querySelectorAll('.fc-timegrid-bg-harness') as NodeListOf<HTMLElement>;
        schedules.forEach((schedule, i) => {
            schedule.style.height = heights[i] + 'px';
        });
    }

    private adjustCalendarHeight() {
        const calendar = document.querySelector('.fc-timegrid') as HTMLElement;
        const calendarBody = document.querySelector('.fc-timegrid-slots') as HTMLElement;

        if (!calendar || !calendarBody) {
            return;
        }

        calendar.style.height = calendarBody.clientHeight + 25 + 'px';
    }

    private resetCalendarStyles() {
        const rows = document.querySelector('.fc-timegrid-slots')?.querySelectorAll('tr');
        rows?.forEach((row) => {
            row.style.display = 'table-row';
        });

        const schedules = document.querySelectorAll('.fc-timegrid-bg-harness') as NodeListOf<HTMLElement>;
        schedules.forEach((schedule) => {
            schedule.style.height = 'auto';
        });

        const calendar = document.querySelector('.fc-timegrid') as HTMLElement;
        calendar.style.height = 'auto';
    }

    private async handleDownloadCalendarPDF() {
        const currentDate = new Date(
            // @ts-ignore
            this.$refs.calendar.$options.parent.$refs.calendar.calendar.currentData.currentDate
        ).getTime();
        const formattedDate = moment(currentDate).format('DD-MM-YYYY');
        const payload = this.preparePDFPayload(currentDate);

        if (!payload.length) {
            this.$notification.error({
                message: 'Error getting data',
                description: 'Please select at least one user',
            });
            return;
        }

        let eventCounter = 0;

        payload.forEach((item: CalendarPDF) => {
            if (item.next_steps.length) {
                eventCounter++;
            }
        });

        if (!eventCounter) {
            this.$notification.error({
                message: 'Error getting data',
                description: 'No events found for selected date',
            });
            return;
        }

        this.loadingOverlay.start();
        let pdf;

        try {
            pdf = await Pdf.downloadCalendarPDF(payload);
        } catch (error) {
            this.$notification.error({
                message: 'Error downloading PDF',
                description: '',
            });
        } finally {
            this.loadingOverlay.stop();
        }

        if (!pdf) {
            return;
        }

        downloadFile(pdf.data, 'Measurement Plan - ' + formattedDate + '.pdf');

        this.$notification.success({
            message: 'Success',
            description: 'Measurement plan PDF downloaded successfully',
        });
    }

    private preparePDFPayload(currentDate: number) {
        if (!this.selectedUsers.length) {
            return [];
        }

        const payload: CalendarPDF[] = [];

        const formattedDate = moment(currentDate).format('DD.MM.YYYY');

        this.selectedUsers.forEach((user) => {
            const payloadObj = {
                date: formattedDate,
                user: [parseInt(user.id, 10)],
                next_steps: this.filterEventsByUserAndDate(user.id, currentDate),
            };

            payload.push(payloadObj);
        });

        return payload;
    }

    private filterEventsByUserAndDate(userId: string, currentDate: number) {
        const filteredEvents: number[] = [];

        this.calendarOptions.events.forEach((event: CalendarEvent) => {
            if (event.selectedUser?.id !== userId) {
                return;
            }

            const currentMomentDate = moment(currentDate);

            if (
                currentMomentDate.isSame(event.start, 'day') ||
                currentMomentDate.isSame(event.end, 'day') ||
                (currentMomentDate.isAfter(event.start, 'day') && currentMomentDate.isBefore(event.end, 'day'))
            ) {
                filteredEvents.push(parseInt(event.id, 10));
            }
        });

        return filteredEvents;
    }

    private async handleEventChange(arg: any) {
        const selectedActivity = NextStepRepository.getById(arg.event._def.extendedProps.eventId);
        if (!selectedActivity) {
            return;
        }

        let assignedUser = null;

        if (selectedActivity.assignedUser) {
            assignedUser = selectedActivity.assignedUser;
        }

        EventBus.$emit(EventBusEvents.openCreateNewEventPopup, {
            popupEvent: EventBusEvents.openCreateNewEventPopup,
            data: {
                eventData: {
                    id: selectedActivity.id,
                    update: true,
                    localDateFrom: selectedActivity.dateFrom,
                    localDateTo: selectedActivity.dateTo,
                    eventName: selectedActivity.name,
                    project: selectedActivity.project,
                    activityType: selectedActivity.type,
                    localReminderOption: selectedActivity.reminderOption?.name,
                    reminderOption: selectedActivity.reminderOption,
                    eventDescription: selectedActivity.note,
                    selectedUser: assignedUser,
                    completed: selectedActivity.completed,
                    canEditActivity: this.canEditActivity,
                    canDeleteActivity: this.canDeleteActivity,
                },
            },
        });
    }

    private debounce(debounceTime: number, info: any) {
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - this.lastTimeStamp;

        if (timeDifference < debounceTime) {
            return;
        }

        this.lastTimeStamp = currentTime;
        this.handleDatesSet(info);
    }

    private async handleDatesSet(info: any) {
        if (this.isUserStateUpdating || this.isUpdatingCalendarPage || this.isCalendarLoading) {
            return;
        }

        this.currentView = info.view.type;
        await this.$nextTick();

        this.updateActivitiesAndResetCalendar();
    }

    private async updateActivitiesAndResetCalendar() {
        this.isCalendarLoading = true;
        this.isUpdatingCalendarPage = true;

        await this.updateActivities();
        this.updateSchedules(this.selectedUsers);
        await this.$nextTick();

        this.isCalendarLoading = false;
        this.isUpdatingCalendarPage = false;
    }

    private async prepareUpdatingActivity(changeType: string, eventData: any) {
        this.isUpdatingCalendarPage = true;

        switch (changeType) {
            case 'save':
                await this.onSave(eventData);
                break;

            case 'update':
                await this.onUpdate(eventData);
                break;

            case 'delete':
                await this.onDelete(eventData);
                break;
        }

        // await this.updateActivities();
        this.isUpdatingCalendarPage = false;
    }

    private formatDatesForCalendar(dateFrom: string | number, dateTo: string | number, type: string = 'calendar') {
        let formattedDateFrom;
        let formattedDateTo;

        switch (type) {
            case 'activity':
                formattedDateFrom = moment(dateFrom).format('YYYY-MM-DDTHH:mm');
                formattedDateTo = moment(dateTo).format('YYYY-MM-DDTHH:mm');
                break;
            default:
                formattedDateFrom = dateFrom ? moment(dateFrom || '-').format('HH:mm') : '';
                formattedDateTo = dateTo ? moment(dateTo || '-').format('HH:mm') : '';
        }

        return { formattedDateFrom, formattedDateTo };
    }

    mounted() {
        this.saveActivityHandler = this.prepareUpdatingActivity.bind(this, 'save');
        this.updateActivityHandler = this.prepareUpdatingActivity.bind(this, 'update');
        this.deleteActivityHandler = this.prepareUpdatingActivity.bind(this, 'delete');

        EventBus.$on('on-save-activity', this.saveActivityHandler);
        EventBus.$on('on-update-activity', this.updateActivityHandler);
        EventBus.$on('on-delete-activity', this.deleteActivityHandler);
        EventBus.$on('on-save-project', this.handleSaveProject);
    }

    beforeDestroy() {
        EventBus.$off('on-save-activity', this.saveActivityHandler as Function);
        EventBus.$off('on-update-activity', this.updateActivityHandler as Function);
        EventBus.$off('on-delete-activity', this.deleteActivityHandler as Function);
        EventBus.$off('on-save-project', this.handleSaveProject);
    }

    @Watch('currentLocale')
    private handleCurrentLocaleChange() {
        this.calendarOptions.locale = this.getLocaleForCalendar();
    }
}
